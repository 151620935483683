
<template>
    <div class="solutionBox">
        <div class="solution-banner">
          <div class="solution-banner-text">
            <div class="solution-banner-text-top">网络学习空间建设与应用方案</div>
            <div class="solution-banner-text-center">优学派智慧课堂</div>
            <div class="solution-banner-text-bottom">教育专属硬件   丰富同步资源   个性化学习   精准化教学   差异化管理   智能化服务   移动式授课</div>
          </div>
        </div>
        <!--<div class="banners">-->
            <!--<img src="@/assets/images/solution.png" />-->
        <!--</div>-->
        <div class="itemImg">
            <h1>优学派智慧教育平台</h1>
            <p>构建起学校、教师、学生、家长互动高效的智慧教育生态圈</p>
            <p>
                <span>
                    <img src="@/assets/images/yes.png" />
                    学校精细化管理
                </span>
                <span>
                    <img src="@/assets/images/yes.png" />
                    教师精准化教学
                </span>
                <span>
                    <img src="@/assets/images/yes.png" />
                    学生个性化学习
                </span>
                <span>
                    <img src="@/assets/images/yes.png" />
                    家校实时互通
                </span>
            </p>
            <div class="bottomImg">
                <img src="@/assets/images/educationPlatform.png" style="width: 965px;height:435px" />
            </div>
        </div>
        <div class="itemImg">
            <h1>教育专属智能硬件</h1>
            <p>护眼管控，为学生健康成长保驾护航</p>
            <div class="bottomImg">
                <img src="@/assets/images/hardware.png" style="width: 928px;height:404px;margin-bottom: 40px" />
            </div>
        </div>
        <div class="itemImg">
            <h1>优质教学资源共享</h1>
            <p>涵盖K12全学段主流同步教材、教辅，精品题库，精品视频等</p>
            <p>
                <span>
                    <img src="@/assets/images/yes.png" />
                    <em>体系化、常态化资源智能推送</em>
                </span>
                <span>
                    <img src="@/assets/images/yes.png" />
                    <em>助力教师精准化教学，学生个性化学习</em>
                </span>
            </p>
            <div class="bottomImg">
                <img src="@/assets/images/share.png" style="width: 1000px;height:331px;margin-bottom: 86px;object-fit: contain;" />
            </div>
        </div>
        <div class="itemImg">
            <h1>精细多维教学管理</h1>
            <p>汇聚教情学情，精细化管理区域信息化建设</p>
            <p class="administrationP">
                <span>
                    <img src="@/assets/images/yes.png" />
                    体系化
                </span>
                <span>
                    <img src="@/assets/images/yes.png" />
                    精细化
                </span>
                <span>
                    <img src="@/assets/images/yes.png" />
                    聚合性
                </span>
                <span>
                    <img src="@/assets/images/yes.png" />
                    多维度
                </span>
            </p>
            <div class="bottomImg">
                <img src="@/assets/images/administration.png" style="width: 1000px;height:359px;margin-bottom: 30px"/>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {};
    },
};
</script>

<style lang="scss" rel="stylesheet/scss">
  .solutionBox {
    width: 100%;
  }
.solutionBox .itemImg p span:nth-child(2) em{
    color:#3173b1FF;
}
.solutionBox .itemImg p span:nth-child(1) em{
    color:#5137a2FF;
}
.solutionBox .itemImg p em{
    font-style: normal;
}
.solutionBox .bottomImg{
    display: flex;
    justify-content: center;
    width: 100%;
}
.solutionBox .bottomImg img {
    /*max-width: 100%;*/
    display: block;
}
.solutionBox .itemImg p:nth-child(3) span{
    margin: 0 13px;
}
.solutionBox .itemImg p:nth-child(3){
    justify-content: center;
    display: flex;
    margin-bottom: 50px;
}
.solutionBox .itemImg p:nth-child(2){
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 42px;
    /*text-align: center;*/
    color: #3E3A39FF;
    display: flex;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
}
.solutionBox .itemImg h1{
    /*text-align: center;*/
    color: #000;
    font-size: 39px;
    line-height: 39px;
    margin-bottom: 41px;
    display: flex;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
}
.solutionBox .itemImg{
    /*width: 70%;*/
    /*margin-left: 15%;*/
  width: 100%;
  padding-top: 60px;
}

.itemImg:nth-child(odd) {
  background: linear-gradient(0deg,rgba(232,240,247,0.7),rgba(244,248,252,0.7));
}

.solutionBox .banners img{
    width: 100%;
}
.solutionBox .administrationP span{
    margin: 0 30px !important;
    line-height: 22px;
}

.solution-banner {
  width: 100%;
  height: 375px;
  background-image: url(~@/assets/images/solution.png);
  background-repeat: no-repeat;
  background-size: 2293px 375px;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  &-text {
    margin-top: 42px;
    margin-right: 450px;
    &-top {
      font-size:24px;
      color:#ffffff;
      line-height:24px;
      margin-bottom: 34px;
    }
    &-center {
      font-size:53px;
      color:#ffffff;
      line-height:53px;
      margin-bottom: 60px;
    }
    &-bottom {
      font-size:12px;
      color:#ffffff;
      line-height:12px;
      white-space: pre-wrap;
    }
  }
}
</style>
